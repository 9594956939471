import type { Ref } from "vue"
import type { Question, QuestionOption } from '~/services/profile/questionnaireService'

import { QuestionType } from '~/services/profile/questionnaireService'

export interface QuestionOptionGroup {
  title?: string,
  alignLeft: boolean,
  options: QuestionOption[]
}

export function useQuestionData(currentQuestion: Ref<Question>) {
  const isMultipleSelect = computed(() => currentQuestion.value.type == QuestionType.MultipleChoices)

  const showScentNotice = computed(() => currentQuestion.value.id == 'skin_preferred_scent')

  const isNoBackground = computed(() => {
    switch (currentQuestion.value.id) {
      case "skin_color":
      case "hair_color":
      case "lipstick_color":
      case "shadow_color":
      case "eyeliner_color":
        return true

      default:
        return false
    }
  })

  const optionImage = (option: string) => {
    switch (currentQuestion.value.id) {
      case "skin_color":
        return `#skin_color_${option}`

      case "skin_oilness":
        return `#skin_oilness_${option}`

      case "facial_skin_oilness":
        return `#skin_oilness_${option}`

      case "body_skin_oilness":
        return `#skin_oilness_${option}`

      case "skin_preferred_scent":
        return `#skin_preferred_scent_${option}`

      case "hair_color":
        return `#hair_color_${option}`

      case "hair_type":
        return `#hair_type_${option}`

      case "hair_oilness":
        return `#hair_oilness_${option}`

      case "hair_chemical":
        return `#hair_chemical_${option}`

      case "lipstick_color":
        return `#lipstick_color_${option}`

      case "shadow_color":
        return `#shadow_color_${option}`

      case "eyeliner_color":
        return `#eyeliner_color_${option}`

      default:
        return undefined
    }
  }

  const optionsGroups = computed(() => {
    switch (currentQuestion.value.id) {
      case "desired_makeup_product":
        const faceOptions = ["foundation", "concealer", "primer", "blush", "face_powder", "illuminator", "bronzer"]
        const mouthOptions = ["regular_lipstick", "liquid_lipstick", "gloss", "lip_liner", "lip_tint"]
        const eyesOptions = ["pencil_eyeliner", "eyeliner", "eyeshadow", "mascara", "eyebrow_products"]
        const otherOptions = ["brush", "makeup_sponge"]

        return [
          { title: "Rosto", alignLeft: true, options: currentQuestion.value.options.filter((option) => faceOptions.indexOf(option.value) >= 0) },
          { title: "Boca", alignLeft: true, options: currentQuestion.value.options.filter((option) => mouthOptions.indexOf(option.value) >= 0) },
          { title: "Olhos", alignLeft: true, options: currentQuestion.value.options.filter((option) => eyesOptions.indexOf(option.value) >= 0) },
          { title: "Diversos", alignLeft: true, options: currentQuestion.value.options.filter((option) => otherOptions.indexOf(option.value) >= 0) }
        ]

      case "desired_skincare_product":
        const soapOptions = ["liquid_facial_soap", "solid_facial_soap", "liquid_body_soap", "solid_body_soap"]
        const moisturizersOptions = ["hand_lotion", "body_lotion", "face_moisturizer", "lip_moisturizer"]
        const treatmentsOptions = ["body_exfoliator", "facial_exfoliator", "facial_mask", "eye_mask", "eye_area_treatment"]
        const routineOptions = ["facial_toner", "facial_serum", "sunscreen", "facial_cleansing", "makeup_remover", "fragrance", "deodorant", "body_oil"]

        return [
          { title: "Sabonetes", alignLeft: true, options: currentQuestion.value.options.filter((option) => soapOptions.indexOf(option.value) >= 0) },
          { title: "Hidratantes", alignLeft: true, options: currentQuestion.value.options.filter((option) => moisturizersOptions.indexOf(option.value) >= 0) },
          { title: "Tratamentos", alignLeft: true, options: currentQuestion.value.options.filter((option) => treatmentsOptions.indexOf(option.value) >= 0) },
          { title: "Dia a dia", alignLeft: true, options: currentQuestion.value.options.filter((option) => routineOptions.indexOf(option.value) >= 0) }
        ]

      case "desired_hair_product":
        return [{ title: null, alignLeft: true, options: currentQuestion.value.options }]

      default:
        return [{ title: null, alignLeft: false, options: currentQuestion.value.options }]
    }
  })

  return {
    isMultipleSelect,
    showScentNotice,
    isNoBackground,
    optionImage,
    optionsGroups
  }
}
