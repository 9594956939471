export interface Questionnaire {
  tenant : string
  id: number
  title: string
  questions: Question[]
}

export enum QuestionType {
  SingleChoice = "SINGLE_CHOICE",
  MultipleChoices = "MULTIPLE_CHOICES"
}

export interface Question {
  id: string
  type: QuestionType
  title: string
  options: QuestionOption[]
}


export interface QuestionOption {
  name: string,
  value: string
}

export default {
  async fetchLatest(): Promise<Questionnaire> {
    return await apiGet(`questionnaire/latest`)
  }
}