<template lang="pug">
.questionnaire
  h2 {{ currentQuestion.title }}
  .subscript
    span(v-if="isMultipleSelect") (Escolha várias opções)
    span(v-else) &nbsp;

  .question-description(v-if="showScentNotice")
    | Considerado na escolha de produtos classificados como "fragrância" ou "hidratante corporal"
    br
    br

  .options-group(v-for="group in optionsGroups")
    h3(v-if="group.title != null") {{ group.title }}

    .options(:class="{ 'align-left': group.alignLeft }")
      QuestionCheckbox(
        v-for="option in group.options"
        :multipleSelect="isMultipleSelect",
        :label="option.name",
        :noBackground="isNoBackground",
        :image="optionImage(option.value)",
        :modelValue="isOptionChecked(option.value)",
        @update:modelValue="newValue => updateOption(option.value, newValue)")

  QuestionSprite

</template>
<script setup lang="ts">
import type {  PropType } from 'vue'
import type { Questionnaire, Question } from '~/services/profile/questionnaireService'
import type { Answer } from '~/services/profile/profileService'

import { QuestionType } from '~/services/profile/questionnaireService'

import QuestionCheckbox from './QuestionCheckbox.vue'
import QuestionSprite from './QuestionSprite.vue'

import { useQuestionData } from '~/mixins/components/profile/questionnaire/QuestionData'

const props = defineProps({
  questionnaire: {
    type: Object as PropType<Questionnaire>,
    required: true
  },
  answers: {
    type: Object as PropType<Answer[]>,
    required: true
  },
  current: {
    type: Number,
    default: 1
  }
})

const minimumSelectedProductTypes = 15

const currentQuestion = computed<Question>(() => {
  return props.questionnaire.questions[props.current - 1]
})

const {
  isMultipleSelect,
  showScentNotice,
  isNoBackground,
  optionImage,
  optionsGroups
} = useQuestionData(currentQuestion)

const currentAnswer = computed<Answer>(() => {
  var answer = props.answers.find((a) => a.questionId == currentQuestion.value.id)

  if (!answer) {
    answer = { questionId: currentQuestion.value.id, choices: [] }

    props.answers.push(answer)
  }

  return answer
})

const isOptionChecked = (option: string) => {
  return currentAnswer.value.choices.indexOf(option) >= 0
}

const updateOption = (option: string, value: boolean) => {
  const isMultipleChoices = currentQuestion.value.type == QuestionType.MultipleChoices

  if (isMultipleChoices) {
    if (value) {
      currentAnswer.value.choices.push(option)
    } else {
      const optionIndex = currentAnswer.value.choices.indexOf(option)

      currentAnswer.value.choices.splice(optionIndex, 1)
    }
  } else {
    currentAnswer.value.choices = [ option ]
  }
}

const numberOfSelectedProductTypes = computed(() => {
  if (props.answers != null) {
    const productsQuestions = [ "desired_skincare_product", "desired_makeup_product", "desired_hair_product" ]

    return props.answers
      .filter((answer) => productsQuestions.indexOf(answer.questionId) >= 0)
      .flatMap((answer) => answer.choices)
      .length
  } else {
    return null
  }
})

const numberOfProductTypesStillNeeded = computed(() => {
  return Math.max(0, minimumSelectedProductTypes - (numberOfSelectedProductTypes.value ?? 0))
})

defineExpose({
  numberOfProductTypesStillNeeded
})
</script>
<style lang="sass" scoped>
.questionnaire
  min-height: 448px

  h2, h3
    margin-bottom: 0

  .subscript
    display: block
    color: #666
    margin-bottom: 16px

  .options
    display: flex
    flex-wrap: wrap
    max-width: 640px
    justify-content: center
    align-items: stretch
    margin: auto
    text-align: left

    &.align-left
      justify-content: left
      max-width: 100%
</style>