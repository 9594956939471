<template lang="pug">
a.question-checkbox(
  href="",
  :class="{ checked: modelValue, 'no-background': noBackground, 'with-image': image != undefined }",
  @click.stop.prevent="toggle")

  svg.image(v-if="image != undefined")
    use(:xlink:href="image")

  | {{ label }}

  .checkmark(v-if="multipleSelect")
    svg(width="14", height="10", viewBox="0 0 26 20", fill="none")
      path(
        id="checkmark",
        d="M3 10.625L7.875 16.25L22.875 3.875",
        stroke-width="3",
        stroke-linecap="round",
        stroke-linejoin="round",
        stroke="#EE3D8A")

</template>
<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
    required: true
  },
  image: {
    type: String
  },
  multipleSelect: {
    type: Boolean,
    default: true
  },
  noBackground: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    required: true
  },
})

const emit = defineEmits(['update:modelValue', 'checked', 'unchecked'])

const toggle = () => {
  emit('update:modelValue', !props.modelValue);

  if (props.modelValue) {
    emit('unchecked')
  } else {
    emit('checked')
  }
}
</script>
<style lang="sass" scoped>
.question-checkbox
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: auto
  max-width: 156px
  min-width: 96px
  font-size: 18px
  line-height: 18px
  padding: 8px 16px
  margin: 12px 8px
  text-align: center
  background-color: #FFECDF
  border-radius: 12px
  cursor: pointer
  user-select: none

  .image
    height: 72px
    width: 72px
    border-radius: 36px
    margin-bottom: 16px

  .checkmark
    position: absolute
    display: none
    justify-content: center
    align-items: center
    top: -12px
    right: -12px
    height: 24px
    width: 24px
    border: 3px solid #EE3D8A
    border-radius: 16px
    background-color: #FFECDF
    box-shadow: inset 0 0 0 2px #fff

    svg > path
      position: absolute
      top: 0px
      left: 0px
      height: 20px
      width: 22px
      z-index: 100
      stroke-dasharray: 27
      stroke-dashoffset: 27
      stroke: #EE3D8A

  &.no-background
    background-color: rgba(0, 0, 0, 0)
    justify-content: flex-start

    .checkmark
      top: 10px
      right: 50%
      transform: translateX(46px)

  &.with-image
    flex-grow: 1

  &.checked

    &::before
      content: ''
      position: absolute
      width: calc(100% + 4px)
      height: calc(100% + 4px)
      border-radius: 16px
      border: 3px solid #EE3D8A

    &.no-background
      .image
        border: 3px solid #EE3D8A

      &::before
        border: 0

    .checkmark
      display: flex

      path
        animation-duration: 120ms
        animation-fill-mode: forwards
        animation-timing-function: cubic-bezier(.72,.01,.83,.83)
        animation-name: fill-stroke

@keyframes fill-stroke
  0%
    stroke-dashoffset: 27

  100%
    stroke-dashoffset: 0
</style>
